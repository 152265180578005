import React from 'react';
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../drupal-oauth/withDrupalOauthConsumer';

// s4n
import { injectIntl, Link, navigate } from "gatsby-plugin-intl"
import { userLogout } from './../../Shop/ext/redux/actions/user'
import GeneralUtils from './../../../../utils/common'
import FormLoginRegisterReset from './../../../forms/AccountManagement/LoginRegisterReset'


class Component extends React.Component {
    constructor(props) {
        super(props);

        // this.t = this.props.intl.formatMessage;
    }

    forceLogout = async () => {
        if (!this.props.drupalOauthClient) {
            return false;
        }

        await this.props.drupalOauthClient.handleLogout();
        this.props.updateAuthenticatedUserState(false);
        this.props.onUserLogout();
    }

    render() {
        const { userAuthenticated: isUserAuthenticated, intl: { formatMessage: t } } = this.props;

        const hasGetParameterAction = GeneralUtils.findGetParameter(`action`);
        const hasGetParameterDestination = GeneralUtils.findGetParameter(`destination`);

        if (isUserAuthenticated) {
            if (`logout` === hasGetParameterAction) { // returning from Drupal site after password reset, we need to logout because it rises expceptions in react, debug why!?
                this.forceLogout();
            } else if (hasGetParameterDestination) {
                navigate(hasGetParameterDestination);
            } else {
                navigate(`/user/profile`); // Redirect after login right away to profile page
            }
        }

        if (`logout` === hasGetParameterAction) { // returning from Drupal site after password reset, we need to logout because it rises expceptions in react, debug why!?
            navigate(`/user/login-register`); // Redirect after login right away to profile page
        }

        return (
            <React.Fragment>
                <div className="container my-5      login-register__page user-password-reset__page">
                    <div className="row">
                        <div className="col-md-12">
                            {/* {hasGetParameterDestination &&
                                <div className="row flex-column align-content-end continue-as-guest">                                   
                                    <div className="col col-lg-12 col-xl-8">
                                        <h1>Bez logowania</h1>
                                    </div>
                                    <div className="col-xl-8 text-right">
                                        <p className={`mb-3 text-center`}>
                                            <Link to={hasGetParameterDestination} className={`btn btn-success`}>Kontynuuj jako gość</Link>
                                            <span className={`d-block my-3`}>lub</span>
                                        </p>
                                    </div>
                                </div>
                            } */}

                            <div className="row flex-column align-content-end login">
                                <div className="col col-lg-12 ___col-xl-8">
                                    <h1>Logowanie</h1>
                                </div>
                                <div className="col col-lg-12 ___col-xl-8">
                                    <FormLoginRegisterReset context={`login`} />
                                </div>
                            </div>
                        </div>                        
                        {/* <div className="col-md-6">
                            <div className="row flex-column align-content-start register">
                                <div className="col col-lg-12 col-xl-8">
                                    <h1>Nie masz konta?</h1>
                                </div>
                                <div className="col col-lg-12 col-xl-8">
                                    <FormLoginRegisterReset context={`register`} passwordConfirm={false} emailConfirm={false} />
                                </div>
                            </div>
                            
                        </div> */}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    onUserLogout: () => dispatch(userLogout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withDrupalOauthConsumer(Component)));